import { Routes } from '@angular/router';
import { authGuard } from './shared/guards/auth.guard';

export const routes: Routes = [
  { path: '', redirectTo: 'subject-list', pathMatch: 'full' },
  { path: 'login', loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage), data: { title: 'AETERNA' } },
  { path: 'login/:routeTo', loadComponent: () => import('./pages/login/login.page').then(m => m.LoginPage), data: { title: 'AETERNA' } },
  { path: 'register', loadComponent: () => import('./pages/register/register.page').then(m => m.RegisterPage), data: { title: 'AETERNA' } },
  { path: 'personal-info', loadComponent: () => import('./widgets/profile/profile.page').then(m => m.ProfilePage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'ch-email', loadComponent: () => import('./widgets/profile/pages/ch-email/ch-email.page').then(m => m.ChEmailPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'ch-password', loadComponent: () => import('./widgets/profile/pages/ch-password/ch-password.page').then(m => m.ChPasswordPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'liveassignment/:joinCode', loadComponent: () => import('./pages/liveassignment/liveassignment.page').then(m => m.LiveassignmentPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'scene-add', loadComponent: () => import('./pages/scene/add/scene-add.page').then(m => m.SceneAddPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'scene-add/:sceneId', loadComponent: () => import('./pages/scene/add/scene-add.page').then(m => m.SceneAddPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'scene/:sceneId', loadChildren: () => import('./pages/scene/details/scene-details.routes').then(m => m.routes) },
  { path: 'quiz-validation/:id', loadComponent: () => import('./pages/quiz-validation/quiz-validation.page').then(m => m.QuizValidationPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'quiz-validation', loadComponent: () => import('./pages/quiz-validation/quiz-validation.page').then(m => m.QuizValidationPage) },
  { path: 'subject-list', loadComponent: () => import('./pages/subject/list/subject-list.page').then(m => m.SubjectListPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'subject-add', loadComponent: () => import('./pages/subject/add/subject-add.page').then(m => m.SubjectAddPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'subject-add/:subjectId', loadComponent: () => import('./pages/subject/add/subject-add.page').then(m => m.SubjectAddPage), data: { title: 'AETERNA' }, canActivate: [authGuard] },
  { path: 'profile-delete-help', loadComponent: () => import('./pages/profile-delete-help/profile-delete-help.page').then(m => m.ProfileDeleteHelpPage), data: { title: 'AETERNA' } },
  { path: '**', redirectTo: 'subject-list', pathMatch: 'full' },
];
