import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TranslateService {
  data: any = {};
  selectedLanguage = 'hu';

  constructor(private http: HttpClient) {}

  use(lang: string | null): Promise<any> {
    lang = lang ? lang : localStorage.getItem('lang');
    return new Promise<any>(resolve => {
      const langPath = `assets/i18n/${lang || this.selectedLanguage}.json`;
      this.selectedLanguage = lang || this.selectedLanguage;
      localStorage.setItem('lang', this.selectedLanguage);
      this.http.get<any>(langPath).subscribe(
        translation => {
          this.data = Object.assign({}, translation || {});
          return resolve(this.data);
        },
        () => {
          this.data = {};
          return resolve(this.data);
        }
      );
    });
  }

  get(): string {
    return this.selectedLanguage;
  }
}
