import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'translate',
  pure: false,
})
@Injectable({
  providedIn: 'root',
})
export class TranslatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(key: any): any {
    let upperKey = '';
    if (typeof key === 'string') {
      upperKey = key.toUpperCase();
    }
    if (this.translateService.data[key]) {
      return this.translateService.data[key];
    } else if (upperKey && this.translateService.data[upperKey]) {
      return this.translateService.data[upperKey];
    }
    return key;
  }
}
