import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { collection, FBaseService } from '../services/firebase/f-base.service';

export const authGuard = async () => {
  const authService = inject(AuthService);
  const router = inject(Router);
  const firebaseService = inject(FBaseService);
  try {
    const user = await firstValueFrom(authService.currentUser());
    if (user) {
      const fbuser = await firstValueFrom(firebaseService.getById(collection.users, user.uid));
      if (fbuser) {
        const role = fbuser.role || null;
        if (role === 'TEACHER') {
          return true;
        }
      }
    }
  } catch (error) {
    console.error(error);
  }
  return router.parseUrl('/login');
};
