import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference, Query } from '@angular/fire/compat/firestore';
import { QueryParam } from '../../models/query-parameter.model';
import { OrderBy } from '../../models/order-by.model';
import { Observable } from 'rxjs';
import { LoggerService } from '../misc/logger.service';
import { getAttributeByPath } from '../../functions/attribute-by-path.function';

// import { getCountFromServer, collection as coll, query, where } from '@angular/fire/firestore';

export const collection = {
  users: 'Users',
  quizzes: 'Quizzes',
  quizpackages: 'QuizPackages',
  liveassignments: 'LiveAssignments',
  liveassignmentparticipants: 'LiveAssignmentParticipants',
  quizresponses: 'QuizResponses',
  groups: 'Groups',
  subjects: 'Subjects',
  lessons: 'Lessons',
  generationProcesses: 'GenerationProcesses',
  generationProcessUserJoins: 'GenerationProcessUserJoins',
  knowledgeElement: 'KnowledgeElements',
  fields: 'Fields',
  topics: 'Topics',
  subtopics: 'Subtopics',
  scenes: 'Scenes',
  studentSceneStatistics: 'StudentSceneStatistics',
  selfAssignments: 'SelfAssignments',
  neptunExports: 'NeptunExports',
};

@Injectable({
  providedIn: 'root',
})
export class FBaseService {
  constructor(
    private afs: AngularFirestore,
    private logger: LoggerService
  ) {}

  get(collectionName: string, limit?: any, orderBy?: OrderBy, startAt?: any, field?: string, fieldPath = 'parentId', opStr = '=='): Observable<any> {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (field) {
          query = query.where(fieldPath, opStr as any, field);
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy?.active && orderBy?.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          query = query.orderBy('id');
        }
        if (startAt) {
          query = query.startAt(startAt[orderBy?.active ? orderBy.active : 'id']);
        }
        return query;
      })
      .valueChanges();
  }

  getByMultiField(collectionName: string, limit = 24, orderBy?: OrderBy[], startAt?: any, queryParam?: QueryParam[]): Observable<any> {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (queryParam) {
          queryParam.forEach(e => {
            if (e.field && e.opStr && e.value) {
              query = query.where(e.field, e.opStr as any, e.value);
            }
          });
        }
        if (limit) {
          query = query.limit(limit);
        }
        if (orderBy?.length) {
          orderBy.forEach((orderElement: OrderBy) => {
            query = query.orderBy(orderElement.active, orderElement.direction ? orderElement.direction : undefined);
          });
        } else {
          query = query.orderBy('id');
        }
        if (startAt && orderBy) {
          query = query.startAt(...orderBy?.map(item => getAttributeByPath(startAt, item.active.split('.'))));
        }
        return query;
      })
      .valueChanges();
  }

  getById(collectionName: string, id: string): Observable<any> {
    return this.afs.collection(collectionName).doc(id).valueChanges();
  }

  getByName(collectionName: string, name: string, limit: number = 1, fieldValue: string = 'name', opStr = 'array-contains', org?: string | number | boolean, parentPath = 'parentId'): Observable<any> {
    return this.afs
      .collection(collectionName, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where(parentPath, '==', org);
        }
        if (name) {
          query = query.where(fieldValue, opStr as any, name);
        }
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  searchByValue(collection: string, field: string, value: string, limit: number, org?: string): Observable<any> {
    return this.afs
      .collection(collection, ref => {
        let query: CollectionReference | Query = ref;
        if (org) {
          query = query.where('parentId', '==', org);
        }
        query = query.orderBy(field);
        query = query.startAt(value);
        query = query.limit(limit);
        return query;
      })
      .valueChanges();
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  async add(collectionName: string, data: any, id?: string) {
    const uid = id ? id : this.afs.createId();
    data.id = uid;
    await this.afs.collection(collectionName).doc(uid).set(data);
    return uid;
  }

  // async countDataByField(collection: string, wh: { fieldPath: string; value: any; opStr: any }[] = []): Promise<number> {
  //   const filters = [];
  //   for (const w of wh) {
  //     filters.push(where(w.fieldPath, w.opStr, w.value));
  //   }
  //   return (await getCountFromServer(query(coll(this.afs.firestore, collection), ...filters))).data().count;
  // }

  getDataByField(collection: string, where: { fieldPath: string; value: any; opStr: any }[] = [], orderBy?: OrderBy, limit?: number, startAfter?: any, noOrder: boolean = false): Observable<any[]> {
    // console.log(collection, where, orderBy, limit, startAfter, noOrder);
    return this.afs
      .collection(collection, ref => {
        let query: CollectionReference | Query = ref;
        for (const w of where) {
          query = query.where(w.fieldPath, w.opStr, w.value);
        }
        if (orderBy && orderBy.active && orderBy.direction) {
          query = query.orderBy(orderBy.active, orderBy.direction);
        } else {
          if (!noOrder) {
            query = query.orderBy('metadata.createdAt', 'desc');
          }
        }
        if (startAfter) {
          query = query.startAfter(startAfter);
        }
        if (limit) {
          query = query.limit(limit);
        }
        return query;
      })
      .valueChanges();
  }

  getDataByFieldSnapshot(collection: string, fieldPath: string, opStr: any, value: any): Promise<any[]> {
    return new Promise((resolve, reject) => {
      const data: any[] = [];
      this.afs
        .collection(collection)
        .ref.where(fieldPath, opStr, value)
        .get()
        .then(snapshot => {
          snapshot.forEach(result => {
            data.push(result.data());
          });
          resolve(data);
          return;
        })
        .catch(error => {
          reject(error);
          return;
        });
    });
  }

  saveOrUpdateData(collection: string, data: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (collection && data) {
        if (!data.id) {
          data.id = this.afs.createId();
        }
        if (!data.metadata) {
          data.metadata = {};
        }
        if (!data.metadata.createdBy) {
          data.metadata.createdBy = {};
        }
        if (!data.metadata.createdBy.id) {
          data.metadata.createdBy.id = this.logger.getUserId();
        }
        if (!data.metadata.createdAt) {
          data.metadata.createdAt = new Date().toISOString();
        }
        if (!data.metadata.lastUpdatedBy) {
          data.metadata.lastUpdatedBy = {};
        }
        // Always update lastUpdated at and by
        data.metadata.lastUpdatedAt = new Date().toISOString();
        data.metadata.lastUpdatedBy.id = this.logger.getUserId();
        this.afs
          .collection(collection)
          .doc(data.id)
          .set(data)
          .then(() => {
            resolve(data);
          })
          .catch(error => {
            reject(error);
          });
      } else {
        reject();
      }
    });
  }

  getUid() {
    return this.afs.createId();
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  set(collectionName: string, id: string, data: any) {
    return this.afs.collection(collectionName).doc(id).set(data);
  }

  /** @deprecated Use 'saveOrUpdateData(collection: string, data: any)' instead */
  update(collectionName: string, id: string, data: any) {
    return this.afs.collection(collectionName).doc(id).update(data);
  }

  delete(collectionName: string, id: string) {
    return this.afs.collection(collectionName).doc(id).delete();
  }
}
