import { Injectable, OnDestroy } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { NavController } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { StorageService } from './misc/storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService implements OnDestroy {
  loggedIn: boolean = false;
  sub: Subscription | null = null;

  constructor(
    private afAuth: AngularFireAuth,
    private navController: NavController,
    private storageService: StorageService
  ) {
    this.sub = this.afAuth.authState.subscribe(data => {
      this.loggedIn = localStorage.getItem('loggedIn') === 'true';
      if (this.loggedIn && !data) {
        this.logout();
        this.navController.navigateRoot('/login');
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  async login(email: string, password: string) {
    const res = await this.afAuth.signInWithEmailAndPassword(email, password);
    if (res) {
      this.loggedIn = true;
      localStorage.setItem('loggedIn', this.loggedIn.toString());
    }
    return res;
  }

  async logout() {
    this.loggedIn = false;
    localStorage.setItem('loggedIn', this.loggedIn.toString());
    await this.storageService.set('sceneId', null);
    await this.afAuth.signOut();
  }

  currentUser(): Observable<firebase.default.User | null> {
    return this.afAuth.user;
  }

  getAFAuth() {
    return this.afAuth;
  }

  async newPassword(newPassword: string) {
    return this.afAuth.currentUser.then(user => user?.updatePassword(newPassword));
  }

  async newEmail(newEmail: string) {
    return this.afAuth.currentUser.then(user => user?.updateEmail(newEmail));
  }

  async updateCurrentUserName(name: string) {
    return this.afAuth.currentUser.then(user =>
      user?.updateProfile({
        displayName: name,
      })
    );
  }

  async passwordRemind(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  async createUser(email: string, password: string, name?: string) {
    return this.afAuth.createUserWithEmailAndPassword(email, password).then(result => {
      if (name) {
        this.updateCurrentUserName(name);
      }
      return result.user;
    });
  }
}
